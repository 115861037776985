var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"search"},[_c('div',{staticClass:"title"},[_vm._v("活动设置列表")]),_c('hr'),_c('el-button',{staticStyle:{"margin-top":"20px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({ path: '/inviteSetting', query: { type: _vm.type } })}}},[_vm._v("新增活动")])],1),_c('el-table',{staticStyle:{"width":"100%","margin-top":"20px"},attrs:{"data":_vm.tableData,"border":"","tooltip-effect":"dark","row-key":_vm.rowKey,"align":"center"}},[_c('el-table-column',{attrs:{"prop":"setId","width":"50","align":"center","label":"ID"}}),_c('el-table-column',{attrs:{"prop":"setName","label":"活动名称","align":"center"}}),_c('el-table-column',{attrs:{"prop":"auto","label":"发放模式","width":"80","align":"center"}}),_c('el-table-column',{attrs:{"prop":"collectionPeriod","label":"领取有效期","width":"300","align":"center"}}),_c('el-table-column',{attrs:{"prop":"activeTime","label":"活动时间","width":"300","align":"center"}}),_c('el-table-column',{attrs:{"prop":"cycle","label":"活动周期（天）","width":"150","align":"center"}}),_c('el-table-column',{attrs:{"prop":"useDay","label":"奖品使用时间（天）","width":"150","align":"center"}}),_c('el-table-column',{attrs:{"prop":"isAdd","label":"是否叠加","width":"120","align":"center"}}),_c('el-table-column',{attrs:{"prop":"strStatus","label":"状态","width":"120","align":"center"}}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.type == 'order')?[(
              scope.row.status == 1 &&
              _vm.$store.state.powerList.indexOf('invite:order:set:edit') !== -1
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.edit(scope.row.setId)}}},[_vm._v("编辑")]):_vm._e(),(
              scope.row.status == 1 &&
              _vm.$store.state.powerList.indexOf('invite:order:set:update') !== -1
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onIsStart(scope.row.setId, 2)}}},[_vm._v("使失效")]):_vm._e(),(
              scope.row.status == 2 &&
              _vm.$store.state.powerList.indexOf('invite:order:set:update') !== -1
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onIsStart(scope.row.setId, 1)}}},[_vm._v("启用")]):_vm._e()]:_vm._e(),(_vm.type == 'register')?[(
              scope.row.status == 1 &&
              _vm.$store.state.powerList.indexOf('invite:user:set:edit') !== -1
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.edit(scope.row.setId)}}},[_vm._v("编辑")]):_vm._e(),(
              scope.row.status == 1 &&
              _vm.$store.state.powerList.indexOf('invite:user:set:update') !== -1
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onIsStart(scope.row.setId, 2)}}},[_vm._v("使失效")]):_vm._e(),(
              scope.row.status == 2 &&
              _vm.$store.state.powerList.indexOf('invite:user:set:update') !== -1
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onIsStart(scope.row.setId, 1)}}},[_vm._v("启用")]):_vm._e()]:_vm._e(),(_vm.type == 'consumption')?[(
              scope.row.status == 1 &&
              _vm.$store.state.powerList.indexOf('invite:fee:set:edit') !== -1
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.edit(scope.row.setId)}}},[_vm._v("编辑")]):_vm._e(),(
              scope.row.status == 1 &&
              _vm.$store.state.powerList.indexOf('invite:fee:set:update') !== -1
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onIsStart(scope.row.setId, 2)}}},[_vm._v("使失效")]):_vm._e(),(
              scope.row.status == 2 &&
              _vm.$store.state.powerList.indexOf('invite:fee:set:update') !== -1
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onIsStart(scope.row.setId, 1)}}},[_vm._v("启用")]):_vm._e()]:_vm._e()]}}])})],1),_c('div',{staticClass:"block"},[_c('div',[_c('el-pagination',{attrs:{"current-page":_vm.params.currentPage,"page-sizes":[5, 10, 20],"page-size":_vm.params.pageSize,"layout":"total,sizes,prev,pager,next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }